import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Features/IR_LEDs/SuccessBox';
import IRTable from 'components/Web_User_Interface/1080p_Series/Features/IR_LEDs/irTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Infrared Nightvision",
  "path": "/Web_User_Interface/1080p_Series/Features/IR_LEDs/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Your camera´s IR LEDs are switched on and off automatically by the internal light sensor. If you want to use your camera behind a highly reflective window or you are using your camera as a weather webcam and don´t need the IR light, simply deactivate the LEDs here.",
  "image": "./WebUI_1080p_SearchThumb_Features_Nightvision.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Features_Nightvision.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // Infrared Nightvision' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your camera´s IR LEDs are switched on and off automatically by the internal light sensor. If you want to use your camera behind a highly reflective window or you are using your camera as a weather webcam and don´t need the IR light, simply deactivate the LEDs here.' image='/images/Search/WebUI_1080p_SearchThumb_Features_Nightvision.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Features_Nightvision.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Features/IR_Nachtsicht/' locationFR='/fr/Web_User_Interface/1080p_Series/Features/IR_LEDs/' crumbLabel="Infrared Nightvision" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a157c39de98527ac5327dde9f33c9792/8dd93/1080p_Settings_Features_Nightvision.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACyUlEQVQoz32RTUhUURTHX5tIW4hmVpakIab5UYpkOJPTB9E4QxN9TEQZY8aURWCp7973defd92ZeQmEpLWphUeDCwChqJ0QfErSqRZtclBBlimNq6Xtv5s3MiZlnUZt+3MW9cH78zzmXQQjhDDwvSDxm1f5zN18H+18Fel/Yp7X3xZnrzy/cetOpDRCBI0Rua2urrK7Zuq2WYRHHIg4hrIbDze79/uMnP8+mPnz9+Wk6Ph61xqPWx2lrbNL4psPw0AN0qqWrJdDqOexrcDRWVDLdLO5iMUJI0yIOZ5PH4wNIJSwTAFIpAIAPE4uRR+O3R+dHHw4OdLT3BEXiO8G5Xd7qWgZhDmEOY6yqYY97H7p8AQB0XU8kErasm4mJ6MKPODy+P3TZ23KjPXg9eMm7O1BTtZ1hMdedTsZqOOLe67p42g8Apmkmk0nIkEwkTGMRAO719T1FR9+ru9/1tT4guH1XLcPzfDo83bbmcDS53V472TDMWCxmGMbCwoKh6wAwMnz3/IG643Vbrh4qfnm+6EmgguG4pYVpmuZ07mz2eCzL0hd1y7IAIGYa36PTeiwOAHcG768t3FReWrU6v7BqfV53w6r0wtjMZ9my15tOTqZS9sBGAmbjMDs5A2b82pWedfkFZWWbc3NzclZm7S3OYjie53j+t+xscrnm52bnohNfvnydmpp88/bTs+djY8NPZkZGBEpWF60pKt2YvXJF9vJljeuY9Mx8RqaUHvP78woKjzRVtPsqN5SUNWyt7m2oj9TX7nc07jl50N9x6tDZozu8zuq6mvKqiqJNJUuyDSEkJMuUSESSREmSJUkRpS5RxBwncJwsy5oWoYpCKZVlWaHyP3IoFFJVhVLFhiqKrNCwoqj2c8mhdgWllBEEXuB5QVhKTlfQ/yFnsO9MJ4u7Ee5kMcKclOlWlIhESCjDn7q/5VAoRAgRRfEXeirEtS8HDqkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a157c39de98527ac5327dde9f33c9792/e4706/1080p_Settings_Features_Nightvision.avif 230w", "/en/static/a157c39de98527ac5327dde9f33c9792/d1af7/1080p_Settings_Features_Nightvision.avif 460w", "/en/static/a157c39de98527ac5327dde9f33c9792/7f308/1080p_Settings_Features_Nightvision.avif 920w", "/en/static/a157c39de98527ac5327dde9f33c9792/19805/1080p_Settings_Features_Nightvision.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a157c39de98527ac5327dde9f33c9792/a0b58/1080p_Settings_Features_Nightvision.webp 230w", "/en/static/a157c39de98527ac5327dde9f33c9792/bc10c/1080p_Settings_Features_Nightvision.webp 460w", "/en/static/a157c39de98527ac5327dde9f33c9792/966d8/1080p_Settings_Features_Nightvision.webp 920w", "/en/static/a157c39de98527ac5327dde9f33c9792/61eb3/1080p_Settings_Features_Nightvision.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a157c39de98527ac5327dde9f33c9792/81c8e/1080p_Settings_Features_Nightvision.png 230w", "/en/static/a157c39de98527ac5327dde9f33c9792/08a84/1080p_Settings_Features_Nightvision.png 460w", "/en/static/a157c39de98527ac5327dde9f33c9792/c0255/1080p_Settings_Features_Nightvision.png 920w", "/en/static/a157c39de98527ac5327dde9f33c9792/8dd93/1080p_Settings_Features_Nightvision.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a157c39de98527ac5327dde9f33c9792/c0255/1080p_Settings_Features_Nightvision.png",
              "alt": "Web User Interface - 1080p Series - Features IR LEDs",
              "title": "Web User Interface - 1080p Series - Features IR LEDs",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your camera´s IR LEDs are switched on and off automatically by the internal light sensor. If you want to use your camera behind a highly reflective window or you are using your camera as a weather webcam and don´t need the IR light, simply deactivate the LEDs here.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IRTable mdxType="IRTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "please-be-aware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#please-be-aware",
        "aria-label": "please be aware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Please be aware`}</h3>
    <p>{`If you deactivate the IR LEDs by night you might get false alerts from the internal motion detection. In this case, you will have to deactivate the motion detection during the night using the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Schedule/"
      }}>{`Alarm Schedule`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      